import React from "react";
import { NavLink } from "react-router-dom";
import { Menu, Instagram, X } from 'react-feather';
import iconDiscord from './../assets/icon-discord.svg';

import './menu.scss';
class MainMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false
    }
    this.toggle = this.toggle.bind(this)
  }
  componentDidMount() {

  }
  componentWillUnmount() {

  }
  toggle() {
    this.setState({
      active: !this.state.active
    })
  }

  render() {
    return (
      <div>
      <div className="menu-open btn-group horizontal" >
        <a href="https://discord.gg/zeKgt7gwZR" style={{ display: 'flex', alignItems: 'center'}}><img src={iconDiscord} style={{ filter: ( this.state.active ? 'invert(100%)' : '' )}} height="20"/></a>

        {( this.state.active ?  <X color="black" onClick={ this.toggle } /> : <Menu color="white" onClick={ this.toggle }  />) }
      </div>
      <nav className="menu" className={( this.state.active ? 'show menu' : 'menu' )}>
        <NavLink extact="true" onClick={ this.toggle } className="menu-item" activeclassname="active" to="/">
          Home
        </NavLink>
        <NavLink  extact="true" onClick={ this.toggle } className="menu-item" activeclassname="active" to="/mint">
          Mint
        </NavLink>
        <NavLink  extact="true" onClick={ this.toggle } className="menu-item" activeclassname="active" to="/headquarters">
          Headquarters
        </NavLink>
        <NavLink  extact="true" onClick={ this.toggle } className="menu-item" activeclassname="active" to="/updates">
          Updates
        </NavLink>
        <NavLink  extact="true" onClick={ this.toggle } className="menu-item" activeclassname="active" to="/battles">
          Battles
        </NavLink>
        <NavLink  extact="true" onClick={ this.toggle } className="menu-item" activeclassname="active" to="/hideout">
          Hideout
        </NavLink>
        <hr></hr>
        <a className="menu-item social" activeclassname="active"  href="https://discord.gg/zeKgt7gwZR"><img src={iconDiscord} width="30" style={{ filter: 'invert(100%)' }}/> Discord</a>
        <a className="menu-item social" activeclassname="active" href="https://instagram.com/sidekick.world"><Instagram size={30} color="black" /> Instagram</a>
      </nav>

      </div>
    )
  }
}

export default MainMenu;