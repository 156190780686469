import React from "react";
import Logo from "./logo";
import Menu from "./menu";
import { ExternalLink } from 'react-feather';

function Footer() {
  return (
    <div className="footer" style={{ textAlign: 'center', paddingBottom: '20px' }}>
      Made with ❤️ by <a href="https://sidekick.studio" alt="Sidekick Studio Alkmaar">Sidekick.Studio <ExternalLink size={14} /></a>
    </div>
  )
}
export default Footer;