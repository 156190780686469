import React from 'react';
import Vimeo from '@u-wave/react-vimeo';  

export default props => {
  return (
    
    <Vimeo
      video="669368066"
      autoplay
      muted
      loop
      controls={false}
      responsive
      style={{ width: '100%', height: 'auto' }}
    />
  
  )
};