import React from "react";
import { NavLink } from "react-router-dom";

function Logo() {
  return (
    <NavLink  extact="true" to="/">
      <svg width="39px" height="32px" viewBox="0 0 39 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <defs>
              <path d="M13.7143749,-3.42708855 L12.2118695,15.3948988 L6.36772235,-0.597688028 L13.7143749,-3.42708855 Z" id="path-vidjg9sacn-1"></path>
              <path d="M19.5,0 L23.7081086,6.90561419 L39,5.1668552 L23.549,15.797 L19.5,9.15233763 L9.604,25.392 L0,32 L19.5,0 Z" id="path-vidjg9sacn-3"></path>
          </defs>
          <g id="website" stroke="none" strokeWidth="1" fill="none">
              <g id="iPhone-13-Copy-8" transform="translate(-32.000000, -44.000000)">
                  <g id="Group" transform="translate(32.000000, 44.000000)">
                      <g id="Sidekick_World" transform="translate(19.500000, 16.000000) rotate(-360.000000) translate(-19.500000, -16.000000) " fill="#ECFE31">
                          <path d="M19.5,0 L23.7081086,6.90561419 L39,5.1668552 L23.549,15.797 L19.5,9.15233763 L9.604,25.392 L0,32 L19.5,0 Z" id="Combined-Shape-Copy-8"></path>
                          <path d="M23.1612038,14.3613829 L21.6890808,32.7717955 L15.9631091,17.1289138 L23.1612038,14.3613829 Z" id="Combined-Shape-Copy-9" transform="translate(19.562156, 23.566589) rotate(-285.000000) translate(-19.562156, -23.566589) "></path>
                      </g>
                      <g id="ave-calvar-Cm7A8naEw7U-unsplash" transform="translate(9.958951, 18.016095)">
                          
                          <use id="Mask" fill="#ECFE31" transform="translate(10.041049, 5.983905) rotate(-645.000000) translate(-10.041049, -5.983905) "></use>
                      </g>
                      <g id="ave-calvar-Cm7A8naEw7U-unsplash">
                          
                          <g id="Mask" transform="translate(19.500000, 16.000000) rotate(-360.000000) translate(-19.500000, -16.000000) "></g>
                      </g>
                  </g>
              </g>
          </g>
      </svg>
    </NavLink>
  )
}

export default Logo;