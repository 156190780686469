import React from "react";
import { ExternalLink } from 'react-feather';

class Construction extends React.Component {
  render() {
      var title = this.props.title;
      return (
        <div class="construction">
          <h2>{title}</h2>
          <p>Available Soon, check <a href="https://discord.gg/zeKgt7gwZR">our discord <ExternalLink size={14} /></a> for updates</p>
        </div>    
      )
  }
}

export default Construction