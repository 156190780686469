import React from "react";
import Logo from "./logo";
import Menu from "./menu";
import "./header.scss";

function Header() {
  return (
    <div className="header">
      <Logo />
      <Menu />
    </div>
  )
}
export default Header;