import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom"
import reportWebVitals from "./reportWebVitals";
import store from "./redux/store";
import { Provider } from "react-redux";
import "./styles/reset.css";
import Header from "./components/header"
import Footer from "./components/footer"
import Hideout from "./routes/hideout";
import Battles from "./routes/battles";
import Updates from "./routes/updates";
import Headquarters from "./routes/headquarters";
import Mint from "./routes/mint";
import Home from "./routes/home";
import './styles/base.scss';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAhDfHteX08Pjno0Hjkf-m6FGX-tCza02Q",
  authDomain: "sidekickworld-2e51c.firebaseapp.com",
  projectId: "sidekickworld-2e51c",
  storageBucket: "sidekickworld-2e51c.appspot.com",
  messagingSenderId: "41028847984",
  appId: "1:41028847984:web:b8e46379d4ad1a83cfe7b5",
  measurementId: "G-J4HKJMPJ8P"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
    <Header />
    <Routes>
      <Route path="/" element={ <Home /> } />
      <Route path="headquarters" element={ <Headquarters /> } />
      <Route path="mint" element={ <Mint /> } />
      <Route path="updates" element={ <Updates /> } />
      <Route path="hideout" element={ <Hideout /> } />
      <Route path="battles" element={ <Battles /> } />
    </Routes>

    <Footer />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
