import "./home.scss";
import Play from './../components/video';
import SidekickOne from "./../assets/sidekick_web.png"; 

export default function Home() {
  return (
    <main style={{ padding: "0" }}>
      <div className="hero-video"><Play></Play></div>
      
      <section className="negative-top">
      <article>
        <h1>Sidekicks<br /> out there,<br />unite!</h1>
        <p>Sidekick is a 10k NFT collection, handcrafted and uniquely randomized with over 200 traits.</p>
      <div className="btn-group">
        <button className="btn cta">Mint (available soon)</button>
        <a href="https://discord.gg/zeKgt7gwZR" className="btn">Join discord</a>
        </div>
        </article>
      </section>
      <div className="spacer large"></div>
      <section style={{ display: 'flex'}}>
        <article className="two-column">
          <div className="image column"><img src={SidekickOne} />
            <div className="spacer small"></div>
          </div>
          <div className="column vertical-center">
            <div className="padding-text">
            <h2>Next gen heroes work together</h2>
              <p>
              It’s time to slay some dragons; together. All beings have super powers, humble or great ones.</p>
              <p> 
              By joining forces we can overcome everything. Use your superpowers to help each other and earn along the way. 
              Request assistance by lighting the Call sign. Mint your Sidekick to become a member of our Sidekick.World and 
              join the heroes of the future. 
              </p>
              <p>See you in the Headquarters on discord.</p>
              </div>
          </div>
        </article>
      </section>
      <div className="spacer large"></div>
      {/* <section>
        <article>
        <h1>Roadmap</h1>
        <table>
        <tbody>
          <tr>
            <td>Q1 2022</td>
            <td>
              <p>Sidekicks are born 555 of 5.555</p>
              <p>Pre-sale minting, join our discord.</p>
            </td>
          </tr>
          </tbody>
        </table>
        </article>
      </section> */}
{/* 
      <section>
        <h1>Why Unite with us?</h1>
        <p></p>
        </section>


      <section>
        <h1>Questions</h1>
        <p></p>
        </section>
 */}
      <section>
        <article>
        <h1>Squad</h1>
        <div className="two-column">
        <div className="column">
          <div className="group row identity">
            <div className="avatar"><img src="https://cdn.discordapp.com/avatars/883029081673838602/537639e4af4ba5b93493c301e423ec53.png?size=512"/></div>
            <div className="vertical-align">
              <h2>Sidekick.Tom</h2>
              <p>Artist & Founding Sidekick</p>
            </div>
          </div>
          <p className="padding right">Designer & Artist for over a decade with a strong believe in blockchain and NFT capabilities for the future. Excited about the decentralized future.</p>
          
          <div className="spacer small"></div>
 </div>
 <div className="column">
          <div className="group row identity">
            <div className="avatar"><img src="https://cdn.discordapp.com/avatars/933341535766978580/05ff7afe61a57673f3004078f1dd15cb.png?size=512" /></div>
            <div className="vertical-align">
            <h2>Sidekick.Luc</h2>
            <p>Artist & Artist & Artist</p>
            </div>
          </div>
          <p className="padding right">Allround artist, character designer & motion specialist with a great mind & heart. Part of the Squad since day one.</p>
          <div className="spacer small"></div>

          </div>
 <div className="column">
          <div className="group row identity">
            <div className="avatar"><img src="https://cdn.discordapp.com/avatars/452857600246153229/23405b8904420beab84a02691e94a9ef.png?size=512" /></div>
            <div className="vertical-align">
              <h2>Rhymo.Eth</h2>
            <p>Advisor & NFT enthousiast</p>
            </div>
          </div>
          <p className="padding right">Always on the hunt for innovation in every digital space possible. Early adapter & founder of the first Augmented reality application back in the days.s</p>

          <div className="spacer small"></div>
          </div>
        </div>

        <div className="btn-group identity">
            <a className="btn cta" href="mailto:tom@sidekick.studio">Unite with the Sidekick Squad </a>
        </div>
        </article>
      </section>
    </main>
  )
}